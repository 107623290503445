/*
 * @Description: 
 */

import { StorageDict, setLocalStorage, getLocalStorage, removeLocalStorage } from '@/utils/storage'

/** 
 * @description 获取token 
 * @returns { void } 返回token
 */
export function getToken() {
  return getLocalStorage(StorageDict.TOKEN)
}

/** 
 * @description 设置token
 * @param { String } val 参数值
 * @returns { void } 返回token
 */
export function setToken(val) {
  setLocalStorage(StorageDict.TOKEN, val)
}

/**
 * @description 删除token
 * @returns { void } 无返回值
 */
export function removeToken() {
  removeLocalStorage(StorageDict.TOKEN)
}